<template>
	<v-container fluid>
		<v-card v-if="!loading">
			<v-tabs show-arrows v-model="tab">
				<v-tab>GENERAL</v-tab>
				<v-tab>FACTURACION</v-tab>
				<v-tab>CONTRATO</v-tab>
				<v-tab>CLIENTE</v-tab>
				<v-tab>CUPS</v-tab>
				<v-tab>DOCUMENTOS</v-tab>
				<v-tab>FACTURAS</v-tab>
				<v-tab>INCIDENCIAS</v-tab>
				<v-spacer />
				<div v-if="$root.acceso('INICIAR_FIRMAS_CONTRATO')" class="d-inline-flex align-center">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								color="secondary"
								icon
								@click="verEvidenciaFirma(datos.CodigoContrato)"
								:disabled="
									!!gettingCert ||
									['Pendiente de Firma', 'Anulado'].includes(datos.Situacion)
								"
								:loading="
									gettingCert == datos.CodigoContrato ||
									['Generando certificado'].includes(datos.Situacion)
								"
							>
								<v-icon>mdi-file-certificate-outline</v-icon>
							</v-btn>
						</template>
						<span>Ver certificado firma</span>
					</v-tooltip>
				</div>
			</v-tabs>

			<v-tabs-items touchless v-model="tab">
				<v-tab-item>
					<v-row class="pa-2">
						<v-col cols="12" class="col-md-6">
							<tarjeta-datos
								:datos="[
									{ nombre: 'Codigo Contrato', valor: datos.CodigoContrato },
									{ nombre: 'Cliente', valor: datos.Denominacion },
									{ nombre: 'Comercializadora', valor: datos.Comercializadora },
								]"
							>
								<v-divider />
								<aux-input title="Situación Contrato">
									<status-chip
										@click.stop="$root.acceso('CAMBIAR_ESTADOS_CONTRATO') ? (cambiarEstado = true) : null"
										small
										:value="datos.TextoSituacion"
									/>
								</aux-input>
							</tarjeta-datos>
						</v-col>

						<v-col cols="12" class="col-md-6">
							<tarjeta-datos
								:datos="[
									{ nombre: 'Contrato', valor: parseDate(datos.FechaContrato) },
									{ nombre: 'Alta', valor: parseDate(datos.FechaAlta) },
									{ nombre: 'Baja', valor: parseDate(datos.FechaBaja) },
									{ nombre: 'Vencimiento', valor: parseDate(datos.FechaVto) },
								]"
							></tarjeta-datos>
						</v-col>

						<v-col cols="12" class="col-md-6">
							<tarjeta-datos
								header
								icon="lightbulb"
								titulo="Datos del suministro"
								:datos="[
									{ nombre: 'CUPS', valor: datos.CodigoCUPS },
									{ nombre: 'Ciudad', valor: datos.TextoCiudad },
									{ nombre: 'Calle', valor: datos.NombreCalle },
									{ nombre: 'Direccion', valor: datos.Direccion },
									{ nombre: 'Aclarador', valor: datos.Aclarador },
									{ nombre: 'Distribuidora', valor: datos.Distribuidora },
									{ nombre: 'Instalación', valor: datos.Comentario },
								]"
							></tarjeta-datos>
						</v-col>
						<v-col cols="12" class="col-md-6">
							<tarjeta-datos titulo="Observaciones" icon="note" header>
								<div class="pa-2">{{ datos.Observaciones }}</div>
							</tarjeta-datos>
							<tarjeta-datos header icon="note-multiple-outline" class="mt-6">
								<template v-slot:titulo>
									Notas Contrato
									<v-btn class="ml-auto" small color="primary" @click.stop="getMessages">
										<v-icon left>mdi-refresh</v-icon>Recargar
									</v-btn>
								</template>
								<v-timeline dense>
									<v-slide-x-transition group>
										<mensaje-timeline
											v-for="(message, i) in mensajesContrato"
											:message="message"
											:key="i"
											small
											@reload="getMessages"
										></mensaje-timeline>
										<nuevo-mensaje-timeline
											:key="newMessage.show"
											v-show="newMessage.show"
											v-model="newMessage"
										/>
									</v-slide-x-transition>
								</v-timeline>
								<v-btn
									v-if="!newMessage.show"
									class="ml-2 mb-2"
									small
									color="primary"
									@click.stop="newMessage.show = true"
								>
									<v-icon left>mdi-plus</v-icon>nuevo mensaje
								</v-btn>
								<template v-else>
									<v-btn
										:disabled="[newMessage.msg, $root.acceso('CONTROL_IMPAGADOS') ? newMessage.tipo : true].some(x => !x)"
										class="ml-2 mb-2"
										small
										color="primary"
										@click.stop="sendNewMessage"
									>
										<v-icon left>mdi-send</v-icon>enviar
									</v-btn>
									<v-btn
										class="ml-2 mb-2"
										small
										color="error"
										@click.stop="newMessage.show = false; newMessage.msg = null"
									>
										<v-icon left>mdi-close</v-icon>cancelar
									</v-btn>
								</template>
							</tarjeta-datos>
						</v-col>
					</v-row>
				</v-tab-item>

				<!-- TAB FACTURACION -->

				<v-tab-item>
					<v-row class="pa-2">
						<v-col cols="12" class="col-lg-3 col-md-4">
							<tarjeta-datos
								header
								titulo="Tarifa"
								icon="email"
								class="mb-2"
								:datos="[
									{ nombre: 'Tarifa', valor: datos.TextoTarifaPeaje },
									{ nombre: 'Grupo', valor: datos.TextoTarifaGrupo },
									{
										nombre: 'Perfil facturación',
										valor: datos.TextoPerfilFacturacion,
									},
								]"
							></tarjeta-datos>

							<tarjeta-datos
								header
								titulo="Tarifas Acceso"
								icon="email"
								:datos="[
									{ nombre: 'Tarifa Peaje', valor: datos.TextoTarifaPeaje },
									{
										nombre: 'Perfil facturación',
										valor: datos.TextoPerfilFacturacion,
									},
								]"
							></tarjeta-datos>

							<tarjeta-datos
								class="mt-2"
								:datos="[
									{ nombre: 'Tipo Periodo', valor: datos.TextoPeriodo },
									{ nombre: 'Tipo Periodo', valor: datos.ModeloFactura },
								]"
							></tarjeta-datos>
						</v-col>
						<v-col cols="12" class="col-lg-4">
							<tarjeta-datos
								header
								titulo="Potencia contratada"
								icon="magnify"
								class="mb-2"
								:datos="
									datos.Potencia.map(item => ({ nombre: item.TextoTarifaPeriodo, valor: item.PotenciaContratada }))
								"
							></tarjeta-datos>

							<tarjeta-datos
								header
								titulo="Sistema contable"
								:datos="[
									{ nombre: 'Codigo Contable', valor: datos.CodigoContable },
								]"
							>
								<v-simple-table>
									<thead>
										<tr>
											<th>Tipo Impuesto</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<div class="d-flex px-2">
												<v-checkbox value="datos.NoFacturar" disabled label="No facturar" />
												<v-spacer />
												<v-checkbox :value="!datos.NoFacturar" disabled label="Estimar" />
											</div>
										</tr>
									</tbody>
								</v-simple-table>
							</tarjeta-datos>
						</v-col>

						<v-col cols="12" class="col-lg-4 col-md-6">
							<tarjeta-datos
								v-if="datos.TextoTarifaGrupo.includes('FLEXIBLE') && $root.acceso('VER_COMISIONES_CONTRATOS')"
								class="mb-6"
								header
								titulo="Producto flexible"
								:datos="[
									{ nombre: 'Fee Energía', valor: datos.datosFlexible.ImporteEnergia * 1 }
								]"
							>
								<v-divider />
								<v-simple-table>
									<template v-slot:default>
										<thead>
											<tr>
												<th class="text-left font-weight-bold">Tarifa Periodo</th>
												<th class="text-left font-weight-bold">Precio Potencia</th>
												<th class="text-left font-weight-bold">FEE calculado</th>
											</tr>
										</thead>
										<tbody>
											<tr v-if="datos.datosFlexible.p1 > 0">
												<td>P1</td>
												<td>{{ datos.datosFlexible.p1 * 1 }}</td>
												<td>{{ datos.datosFlexible.feep1 * 1 }}</td>
											</tr>
											<tr v-if="datos.datosFlexible.p2 > 0">
												<td>P2</td>
												<td>{{ datos.datosFlexible.p2 * 1 }}</td>
												<td>{{ datos.datosFlexible.feep2 * 1 }}</td>
											</tr>
											<tr v-if="datos.datosFlexible.p3 > 0">
												<td>P3</td>
												<td>{{ datos.datosFlexible.p3 * 1 }}</td>
												<td>{{ datos.datosFlexible.feep3 * 1 }}</td>
											</tr>
											<tr v-if="datos.datosFlexible.p4 > 0">
												<td>P4</td>
												<td>{{ datos.datosFlexible.p4 * 1 }}</td>
												<td>{{ datos.datosFlexible.feep4 * 1 }}</td>
											</tr>
											<tr v-if="datos.datosFlexible.p5 > 0">
												<td>P5</td>
												<td>{{ datos.datosFlexible.p5 * 1 }}</td>
												<td>{{ datos.datosFlexible.feep5 * 1 }}</td>
											</tr>
											<tr v-if="datos.datosFlexible.p6 > 0">
												<td>P6</td>
												<td>{{ datos.datosFlexible.p6 * 1 }}</td>
												<td>{{ datos.datosFlexible.feep6 * 1 }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</tarjeta-datos>

							<tarjeta-datos header titulo="Segmentación Contratos" icon="magnify" class="mb-2">
								<v-simple-table>
									<template v-slot:default>
										<thead>
											<tr>
												<th class="text-left font-weight-bold">Segmentación Contratos</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, i) in datos.SegmentacionContratos" :key="i">
												<td>{{ item }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</tarjeta-datos>

							<tarjeta-datos
								:datos="[
									{ nombre: 'Agregación', valor: datos.Agregacion },
									{ nombre: 'Alquiler', valor: datos.TextoAlquiler },
								]"
							></tarjeta-datos>

							<tarjeta-datos
								header
								titulo="Técnico"
								icon="account"
								class="mt-2"
								:datos="[
									{ nombre: 'Tensión', valor: datos.TextoTension },
									{ nombre: 'Fases', valor: datos.TextoFase },
								]"
							></tarjeta-datos>
						</v-col>
					</v-row>
				</v-tab-item>

				<!-- TAB CONTRATO -->

				<v-tab-item>
					<v-row class="pa-2">
						<v-col cols="12" class="col-lg-6">
							<tarjeta-datos
								:datos="[
									{
										nombre: 'CNAE',
										valor: [datos.CodigoCNAE, datos.TextoCNAE]
											.filter((item) => item != null)
											.join(' - '),
									},
									{
										nombre: 'Destino',
										valor: datos.TextoDestino,
									},
								]"
							></tarjeta-datos>
						</v-col>
						<v-col cols="12" class="col-lg-6">
							<tarjeta-datos header titulo="Condiciones Contractuales Especiales" icon="note">
								<v-textarea no-resize filled auto-grow rows="1" hide-details disabled></v-textarea>
							</tarjeta-datos>
						</v-col>
						<v-col cols="12" class="col-lg-3 col-md-4">
							<tarjeta-datos
								:datos="[
									{ nombre: 'Idioma', valor: datos.Textoidioma },
									{
										nombre: 'Tipo Impresión',
										valor: tipoImprimir(datos.TipoImprimir),
									},
									{ nombre: 'Grupo Imprimir', valor: '' },
									{ nombre: 'Modelo Contrato', valor: datos.ModeloContrato },
								]"
							>
								<v-divider />
								<v-checkbox
									label="Bloquear informacion comercial"
									class="ml-3"
									:value="datos.NoEnviarInformacion"
									disabled
								/>
							</tarjeta-datos>
						</v-col>
						<v-col cols="12" class="col-lg-3 col-md-4">
							<tarjeta-datos>
								<!-- <strong class="pa-2">REVISAR CAMPOS</strong> -->
								<v-checkbox label="No renovar contrato" class="ml-3" :value="datos.NoRenovar" disabled />
								<v-divider />
								<v-checkbox
									label="Comunicar cambio Precios"
									class="ml-3"
									:value="datos.ComunicarCambioPrecios"
									disabled
								/>
								<v-divider />
								<v-checkbox
									label="Comunicar Renovación"
									class="ml-3"
									:value="datos.ComunicarRenovacion"
									disabled
								/>
								<v-divider />
								<v-checkbox label="No Cortable" class="ml-3" :value="datos.NoCortable" disabled />
								<v-divider />
								<v-checkbox
									label="Bloquear traspaso Web"
									class="ml-3"
									:value="datos.BloquearTraspasoWeb"
									disabled
								/>
								<v-divider />
								<v-checkbox label="Sin gastos impago" class="ml-3" :value="datos.SinGastoImpago" disabled />
								<v-divider />
							</tarjeta-datos>
						</v-col>
						<v-col cols="12" class="col-lg-3 col-md-4">
							<tarjeta-datos
								header
								titulo="Representante"
								:datos="[
									{ nombre: 'Representante', valor: datos.Representante },
									{ nombre: 'Colectivo', valor: datos.TextoColectivo },
									{ nombre: 'Identificador', valor: datos.IdentificadorRep },
									{ nombre: 'Comentario', valor: datos.Comentario },
								]"
							></tarjeta-datos>
						</v-col>
						<v-col cols="12" class="col-lg-3 col-md-4">
							<tarjeta-datos
								header
								titulo="Banco"
								:datos="[
									{
										nombre: 'Referencia Banco',
										valor: datos.ReferenciaBanco,
									},
									{ nombre: 'Referencia B2B', valor: datos.ReferenciaB2B },
									{ nombre: 'Fecha firma Core', valor: datos.CoreFechaFirma },
									{
										nombre: 'Fecha cambio banco',
										valor: datos.FechaCambioBanco,
									},
								]"
							>
								<v-divider />
								<div class="d-flex pa-4 align-start">
									<span class="font-weight-bold">Fecha Firma B2B</span>
									<v-spacer />
									<v-checkbox label="First" :value="datos.IsFirst" hide-details class="ma-0" disabled />
								</div>
							</tarjeta-datos>
						</v-col>
						<v-col cols="12" class="col-lg-3 col-md-4">
							<tarjeta-datos
								:datos="[
									{
										nombre: 'Calendario cartera',
										valor: datos.TextoCalendario,
									},
									{ nombre: 'Grupos de Cobro', valor: datos.Grupodecobro },
									{ nombre: 'Referencia Externa 1', valor: datos.RefExt1 },
									{ nombre: 'Referencia Externa 2', valor: datos.RefExt2 },
								]"
							></tarjeta-datos>
						</v-col>
						<v-col cols="12" class="col-lg-3 col-md-4">
							<tarjeta-datos :datos="[{ nombre: 'Agente', valor: datos.NombreAgente }]"></tarjeta-datos>
						</v-col>
					</v-row>
					<!-- {{ datos }} -->
				</v-tab-item>

				<!-- TAB CLIENTE -->

				<v-tab-item>
					<DetallesCliente :_idCliente="datos.IdCliente" />
				</v-tab-item>

				<!-- TAB CUPS -->

				<v-tab-item>
					<DetallesCups :CodigoCups="datos.CodigoCUPS" />
				</v-tab-item>

				<!-- TAB DOCUMENTOS -->

				<v-tab-item>
					<v-row no-gutters class="d-flex align-center mx-2">
						<v-col cols="12" sm>
							<v-file-input label="Adjuntar ficheros" filled hide-details="auto" v-model="newFile" dense></v-file-input>
						</v-col>
						<v-col cols="12" sm="auto" class="ml-1 mt-1 mt-sm-0">
							<v-btn
								block
								:disabled="!newFile"
								color="primary"
								large
								@click="sendFile"
								:loading="sendingFile"
							>
								<v-icon left>mdi-upload</v-icon>Subir
							</v-btn>
						</v-col>
					</v-row>
					<documentacion-contrato :key="sendingFile" :codigoContrato="codigoContrato" />
				</v-tab-item>

				<!-- TAB FACTURAS -->

				<v-tab-item>
					<v-container class="pa-2" fluid>
						<Facturacion :codigoContrato="codigoContrato" class="pa-0" />
					</v-container>
				</v-tab-item>

				<!-- TAB INCIDENCIAS -->

				<v-tab-item>
					<v-container class="pa-2" fluid>
						<incidencias class="pa-0 mt-4" />
					</v-container>
				</v-tab-item>
			</v-tabs-items>
		</v-card>
		<v-card v-else>
			<v-overlay absolute>
				<v-progress-circular size="50" indeterminate color="primary"></v-progress-circular>
			</v-overlay>
		</v-card>

		<v-bottom-sheet v-model="changeFileName.show" persistent>
			<v-card class="pa-4">
				<v-row>
					<v-col cols="12" class="col-sm-8 col-md-6 mx-auto">
						<v-card-title>¿Con qué nombre quieres guardarlo?</v-card-title>
						<v-text-field
							v-model="changeFileName.newName"
							filled
							dense
							hide-details="auto"
							:suffix="changeFileName.extension"
							:rules="[changeFileName.req]"
						></v-text-field>
						<v-card-actions>
							<v-spacer />
							<v-btn color="primary" @click="changeFileName.reject(false)" text>Cancelar</v-btn>
							<v-btn color="primary" @click="changeFileName.resolve(true)" text>Aceptar</v-btn>
						</v-card-actions>
					</v-col>
				</v-row>
			</v-card>
		</v-bottom-sheet>

		<v-dialog persistent max-width="600px" width="100%" v-model="cambiarEstado">
			<cambiar-estado
				:contratos="[datos.CodigoContrato]"
				@reload="getDatos"
				@close="cambiarEstado = false"
			/>
		</v-dialog>
	</v-container>
</template>

<script>
// import axios from "redaxios";
import {
	processToken,
	parseDate,
	getSituacionIcon,
	downloadFile,
} from "@/utils/index.js";
import { req } from "@/utils/validations.js";

export default {
	components: {
		NuevoMensajeTimeline: () => import("@/components/NuevoMensajeTimeline.vue"),
		MensajeTimeline: () => import("@/components/MensajeTimeline.vue"),
		TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
		Facturacion: () => import("@/modules/facturacion/views/Facturas.vue"),
		Incidencias: () => import("@/modules/incidencias/views/Incidencias.vue"),
		StatusChip: () => import("@/components/StatusChip.vue"),
		AuxInput: () => import("@/components/AuxInput.vue"),
		CambiarEstado: () => import("../components/CambiarEstado.vue"),
		DocumentacionContrato: () => import("../components/DocumentacionContrato.vue"),
		DetallesCliente: () => import("@/modules/clientes/views/DetallesCliente.vue"),
		DetallesCups: () => import("@/views/DetallesCups.vue"),
	},
	props: ["_idContrato", "_codigoContrato"],
	data() {
		return {
			idContrato: this._idContrato || this.$route.query.idContrato,
			codigoContrato: this._codigoContrato || this.$route.query.codigoContrato,
			development: process.env.NODE_ENV == "development",
			datos: { documentos: [] },
			estados: [],
			tab: Number(this.$route.query.tab) || 0,
			loading: true,
			newFile: null,
			sendingFile: false,
			loadingPdf: false,
			downloadingFile: null,
			cambiarEstado: false,
			nuevoEstado: null,
			gettingCert: false,
			changeFileName: {
				newName: "",
				extension: "",
				show: false,
				resolve: null,
				reject: null,
				req,
			},

			mensajesContrato: [],

			newMessage: {
				show: false,
				files: [],
				msg: null,
			},
		};
	},
	mounted() {
		this.getDatos();
		this.getMessages();
	},
	methods: {
		async getDatos_() {
			const { IdAgente } = this.$store.getters.getDatosEmpresa.iss;
			const { data: contrato } = await axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/contratosVerificados.php`,
				method: 'GET',
				params: {
					token: this.$store.getters.getJwtEmpresa,
					CodigoContrato: this.CodigoContrato
				}
			})
		},
		async getDatos() {
			let str = this.$store.getters.getDatosEmpresa;
			const { IdUsuario, IdAgente } = str.iss;
			const IdEmpresa = this.$store.getters.getIdEmpresa;
			try {
				var { data: detalles } = await axios({
					method: "get",
					url: `${process.env.VUE_APP_API_URL}/Contrato/GetContratoBag/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/IdContrato/${this.idContrato}`,
				});
			} catch (e) {
				this.$root.$emit("snack", "Ha ocurrido un error");
				this.$router.go(-1);
			}

			let flexible = {};
			if (processToken(detalles).iss.TextoTarifaGrupo.includes('FLEXIBLE')) {
				try {
					flexible = await axios({
						method: "get",
						url: `${process.env.VUE_APP_OUR_API_URL}/altaproducto.php`,
						params: {
							productoFlexible: processToken(detalles).iss.TextoTarifaGrupo,
							token: this.$store.getters.getJwtEmpresa,
						}
					});
					for (let [key, value] of Object.entries(flexible.data)) {
						// console.log({key, value})
						if (key.includes('feep')) {
							value = Math.round(value * 2) / 2
						}
						flexible.data[key] = Number(value)
						// console.log(flexible.data)
					}
				} catch (e) {
					this.$root.$emit("snack", "Ha ocurrido un error");
					this.$router.go(-1);
				}
			}

			this.datos = { ...processToken(detalles).iss, datosFlexible: flexible.data, documentos: [] };
			this.loading = false;
		},
		async getMessages() {
			let { data: messages } = await axios({
				method: 'GET',
				url: `${process.env.VUE_APP_OUR_API_URL}/mensajesImpagados.php`,
				params: {
					CodigoContrato: this.codigoContrato,
					token: this.$store.getters.getJwtEmpresa
				}
			})

			const list_to_tree = (list) => {
				let map = {}, roots = [], i;

				for (i = 0; i < list.length; i += 1) {
					map[list[i].idMensaje] = i; // initialize the map
					list[i].children = []; // initialize the children
				}

				list.forEach(node => {
					if (node.idParent && node.idParent != '0') {
						// if you have dangling branches check that map[node.parentId] exists
						list[map[node.idParent]].children.push(node);
					} else {
						roots.push(node);
					}
				})

				return roots;
			}

			this.mensajesContrato = list_to_tree(messages)

		},
		sendNewMessage() {
			const formData = new FormData();
			formData.append('token', this.$store.getters.getJwtEmpresa);
			formData.append('CodigoContrato', this.codigoContrato);
			formData.append('mensaje', this.newMessage.msg);
			formData.append('tipo', this.newMessage.tipo);
			for (let i = 0; i < this.newMessage.files.length; i++) {
				formData.append("documentos[]", this.newMessage.files[i]);
			}
			axios({
				method: "POST",
				url: `${process.env.VUE_APP_OUR_API_URL}/mensajesImpagados.php`,
				data: formData,
			})
				.then(({ data }) => {
					this.newMessage.show = false;
					this.newMessage.msg = "";
					this.newMessage.files = [];
					this.getMessages();
				})
				.catch((err) => console.error(err));
		},
		getSituacionIcon,
		parseDate,
		tipoImprimir(tipo) {
			switch (tipo) {
				case "E":
					return "Email";
				case "P":
					return "Papel & email";
				case "Q":
					return "Papel";
				case "W":
					return "Web";
				case "R":
					return "Recibo Bancario";
				default:
					return tipo;
			}
		},
		verCondicionesContractuales() {
			this.loadingPdf = true;
			let str = this.$store.getters.getDatosEmpresa;
			const { IdUsuario } = str.iss;
			const IdEmpresa = this.$store.getters.getIdEmpresa;
			axios({
				method: "get",
				url: `${process.env.VUE_APP_API_URL}/Contrato/ContratoPreviewPDF/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Contrato/${this.idContrato}`,
				responseType: "blob",
			})
				.then((res) => {
					let pdf = new File(
						[res.data],
						`contrato_${new Date().getTime()}.pdf`,
						{
							type: "application/pdf",
						}
					);
					downloadFile(pdf, true);
				})
				.catch((err) => {
					this.$root.$emit("snack", "Ha ocurrido un error");
				})
				.finally(() => (this.loadingPdf = false));
		},
		async verEvidenciaFirma(codContrato) {
			this.gettingCert = codContrato;
			try {
				var { data } = await axios({
					url: `${process.env.VUE_APP_OUR_API_URL}/certificado_firma.php`,
					method: "GET",
					params: {
						token: this.$store.getters.getJwtEmpresa,
						codigoContrato: this.codigoContrato,
					},
				});
			} catch (e) {
				this.$root.$emit("snack", "No se ha podido recuperar el certificado");
				this.gettingCert = null;
				return;
			}

			downloadFile(data, true);

			this.gettingCert = null;
		},
		changeFileNameF(name) {
			return new Promise((resolve, reject) => {
				this.changeFileName.show = true;
				this.changeFileName.newName = name.split(".").slice(0, -1).join(".");
				this.changeFileName.extension =
					"." + name.split(".")[name.split(".").length - 1];
				this.changeFileName.resolve = resolve;
				this.changeFileName.reject = reject;
			});
		},
		async sendFile() {
			this.sendingFile = true;
			let str = this.$store.getters.getDatosEmpresa;
			const { IdUsuario } = str.iss;
			const IdEmpresa = this.$store.getters.getIdEmpresa;

			const fileToB64 = (file) => {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => resolve(reader.result);
					reader.onerror = (error) => reject(error);
				});
			};

			try {
				await this.changeFileNameF(this.newFile.name);
				this.changeFileName.show = false;
			} catch (e) {
				this.changeFileName.show = false;
				this.sendingFile = false;
				this.newFile = null;
				return;
			}

			let name = `${this.changeFileName.newName}${this.changeFileName.extension}`;
			let encoded = await fileToB64(this.newFile);
			axios({
				url: `${process.env.VUE_APP_API_URL}/Documento/ImportDocumento`,
				method: "POST",
				data: {
					CodigoContrato: Math.abs(this.codigoContrato),
					Documento: encoded.split(",")[1],
					IdEmpresa,
					IdUsuario,
					NombreDocumento: name,
				},
				headers: {
					Authorization: this.$store.getters.getToken,
				},
			})
				.then((res) => {
					this.newFile = null;
					this.$root.$emit("snack", "Documento subido con éxito");
					this.sendingFile = false;
				})
				.catch((err) => {
					console.log(err)
					this.$root.$emit("snack", "Ha ocurrido un error");
				});
		},
	},
};
</script>

<style scoped>
* {
	font-size: 0.9rem;
}
</style>